@import "../../styles/fonts";
@import "../../styles/media";

.welcome-screen {
  .page-content {
    padding: 0 25px 0 25px;

    h1 {
      margin-bottom: 24px;
      @media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
        font-size: 14pt;
        line-height: 25px;
        padding-top: 0;
      }
    }

    p {
      font-size: 12pt;
      text-align: justify;
      margin-bottom: 10px;
      line-height: 20pt;
    }
    @media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
      padding: 0;
    }
  }
}
