@import "reset";
@import "local-fonts";
@import "fonts";
@import "colors";
@import "media";

* {
  box-sizing: border-box;
}

// we need make our outermost container a flex container to fix IE issues
// https://github.com/philipwalton/flexbugs#flexbug-3
// This is workaround 3.2.b
#root {
  display: block;
}

body {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: $NHB_light_gray;
}

em {
  font-style: italic;
}

.app-container {
  font-family: $font;
  color: $NHB_black;
  margin: 0 auto;
  max-width: 1200px;
}

.app-container.private {
  box-shadow: 0px 1px 10px $NHB_gray_5;
  border: 1px solid $NHB_medium_gray;
  @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
    margin: 0;
    .app-content {
      padding: 5px 20px;
      min-height: 72vh;
    }
  }
  @media (min-width: #{$break-medium}) and (max-width: #{$break-large - 1px}) {
    margin: 0;
  }
}
.page-content {
  width: 100%;
  margin-left: -15px;
  padding-left: 22px;

  @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
    border-left: none !important;
    padding: 0;
    margin: 0;
  }
}

.app-content {
  background: $NHB_white;
  padding: 30px;
  min-height: 79vh;
}

h1 {
  font-size: 24pt;
}

a {
  color: $NHB_blue;
  text-decoration: none;
  // &:visited {
  //   color: $pink;
  // }
  &:hover, &:focus {
    color: $blue;
    text-decoration: underline;
  }
}
a,
button,
input,
textarea,
i {
  &:focus {
    outline-style: auto;
    outline-width: 1px;
    outline-color: $NHB_blue;
  }
}
.description {
  padding: 10px 0 10px 0;
  line-height: 25px;
}
.hide {
  display: none;
  visibility: hidden;
}
.sr_hide {
  width: 1px !important;
  height: 1px !important;
  text-indent: -9999px;
  position: absolute;
  z-index: -99;
}
i > span {
  font-weight: initial;
}

@media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
  .private h1 {
    font-size: 16pt;
    margin: 0 0 15px;
    border-left: 2px solid #b4975a;
    padding: 0 0 10px 10px;
    border-bottom: 1px solid #eeeeee;
  }
}
